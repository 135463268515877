@import 'assets/styles/scss/_variables';

.checkout {
    background: none;
    border: 0px none transparent;
    overflow: hidden;
    margin: 0px;
    padding: 0px;
    -webkit-tap-highlight-color: transparent;
    // position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100vh;
    transition: opacity 0.3s ease 0s;
    visibility: visible;
    opacity: 1;

    .checkout__container {
        max-width: 480px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
        height: 100%;

        .checkout__main {
            width: 100%;
            margin: auto;
        }

        .init__loader,
        .loader {
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 100px;
        }
        .init__loader {
            .lds-spinner div:after {
                background: $white !important;
            }
        }

        .checkout__content {
            position: relative;
            border-radius: 8px;
            box-shadow: 0 4px 15px 0 rgb(0 0 0 / 20%);
            background: $white;
            // overflow-x: hidden;

            &.error {
                display: flex;
                align-items: center;
                max-width: 380px;
                max-height: 300px;
                margin: auto;
                padding: 50px;
                text-align: center;
                font-weight: 600;
                p {
                    font-size: 1.4rem;
                    color: $gray-light;
                }
                button {
                    border: 1px solid #d6d6d6;
                    padding: 10px 20px;
                    border-radius: 15px;
                    background: none;
                    color: $gray-light;
                }
            }
            .close__checkout {
                position: absolute;
                top: -14px;
                right: -20px;
                height: 20px;
                width: 20px;
                z-index: 3;
                cursor: pointer;
                display: flex;
                align-items: center;
                border-radius: 50%;

                &:hover {
                    background: red;
                }

                svg {
                    transform: rotate(45deg);
                    path {
                        stroke: $white;
                    }
                }
            }
            .checkout__header {
                background: $primary;
                padding: 10px 20px;
                font-weight: 600;
                box-shadow: 0 1px 1px 0 rgb(0 0 0 / 10%);
                border-radius: 8px 8px 0 0;
                .logo {
                    max-height: 40px !important;
                    max-width: 200px;
                }
                h5 {
                    font-size: 1.4rem;
                }
                .cash {
                    font-family: gilroy-bold;
                    color: $primary;
                    font-size: 1.6rem;
                }
            }
            .dropdown-menu {
                box-shadow: 0 4px 15px 0 rgb(0 0 0 / 20%) !important;
                inset: 25px -35px auto auto !important;
                padding: 1rem 0 !important;
                z-index: 999999;

                .dropdown-item,
                .item {
                    color: #908e8e !important;
                    font-size: 11px;
                    margin-bottom: 5px;
                    padding: 5px 15px !important;
                    min-width: 140px;
                }

                .dropdown-item {
                    cursor: pointer;
                }
            }
            .card__method {
                display: flex;
                justify-content: space-between;
                padding: 10px;
                border-radius: 8px;
                position: relative;
                color: $gray-light;
                font-family: gilroy-bold;
                border: 1px solid #e6ebf1;
                cursor: pointer;
                box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.05);

                &.options {
                    border: 1px solid $primary;

                    svg path {
                        fill: $primary;
                    }
                }

                svg {
                    margin-right: 10px;
                    width: 20px;
                    height: 20px;

                    path {
                        fill: $gray-light;
                    }
                }
                .icon {
                    display: none;
                }

                &.active {
                    color: $primary;
                    border: 2px solid $primary;

                    svg path {
                        fill: $primary;
                    }

                    .icon {
                        background: $primary;
                        position: absolute;
                        right: -5px;
                        top: -5px;
                        width: 15px;
                        height: 15px;
                        transition: all 300ms linear;
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        &::after {
                            content: '\2713';
                            color: $white;
                            font-size: 9px;
                        }
                    }
                }
            }
        }

        .checkout__footer {
            color: $white;
            opacity: 0.7;
            text-align: center;
            padding: 30px 0;
            p {
                font-size: 1.2em;
                span {
                    font-family: gilroy-bold;
                }
            }
            svg path {
                fill: $white;
            }

            &.no__frame {
                color: $dark;
                svg path {
                    fill: $dark;
                }
            }
        }
    }

    .full-line {
        width: 100%;
        background: $primary;
        height: 3px;
        opacity: 0.2;
    }
}

.confirmation__frame__wrapper {
    width: 100%;
    overflow: auto;
    border-radius: 6px;
    color: #908e8e;
}
.confirmation__frame {
    width: 100%;
    min-height: 350px;
    overflow: scroll;
    position: relative;
    background: transparent;
}

.account__container {
    max-height: 195px;
    overflow: scroll;
    margin-bottom: 10px;
}

.account__list {
    border-radius: 5px;
    background: $white;
    border: 1px solid #f2f2f2;
    margin-bottom: 20px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.03);
    display: flex;
    align-items: flex-start;
    padding: 10px 5px;
    height: 64px;
    max-height: 60px;
    cursor: pointer;

    p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 150px;
        margin: 0;
        font-weight: 600;
    }

    &.add__new {
        border: 1px dashed #bdbdbd;
        background: #f2f2f2;
    }

    .bullet {
        border: 1px solid rgba(80, 159, 239, 0.5);
        border-radius: 50%;
        width: 12px;
        height: 12px;
        margin: 3px 10px;
    }

    &:hover,
    &.active {
        border: 1px solid $primary;

        .bullet {
            border: 3px solid rgba(80, 159, 239, 0.5);
        }
    }

    &.disabled {
        cursor: not-allowed;

        &:hover {
            border: 1px solid #f2f2f2;

            .bullet {
                border: 1px solid rgba(80, 159, 239, 0.5);
            }
        }
    }

    img {
        max-height: 10px;
        min-width: 20px;
    }
}

.card__form {
    background: $primary-light;
    border: 1px solid $border-gray;
    border-radius: 8px;
    padding: 20px 15px 0 15px;
    margin: 20px 0 10px 0;
}

.mb-19 {
    margin-bottom: 19px;
}
